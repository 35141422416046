import * as CryptoJS from 'crypto-js'

export class CryptoJsService {
  key = CryptoJS.enc.Utf8.parse('8080808080808080');
  iv = CryptoJS.enc.Utf8.parse('8080808080808080');

  encrypt(text) {
    const encryption = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      this.key,
      {
        keySize: 256 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

    return encryption.toString()
  }

  decrypt(text) {
    const decrypted = CryptoJS.AES.decrypt(text, this.key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
