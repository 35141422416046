import { LoginService } from '../services/LoginService';
import { CryptoJsService } from "../services/CryptoJsService";

export class LoginComponent {

  companyField = null;
  loginField = null;
  passwordField = null;
  schema = null;
  code = null;
  _submitBtn = null;
  _passwordBtn = null;
  invalidCredentialsMessage = null;
  language = null;
  _isRememberInput = null
  _forgotPasswordButtonEl = null;
  _backToLoginButtonEl = null;
  _resetPasswordEmailInput = null;
  _resetPasswordButtonEl = null;
  _resetPasswordStepFirstWrapperEl = null;
  _resetPasswordStepSecondWrapperEl = null;
  _loginViewEl = null;
  _resetPasswordViewEl = null;
  _resetPasswordAgainButtonEl = null;
  _notificationElem = null;
  _notificationCloseButtonEl = null;

  constructor() {
    this.loginService = new LoginService();
    this.cryptoJsService = new CryptoJsService();
  }

  init() {

    this.checkLanguage();
    this.language = document.location.href.includes('/en') ? 'en' : 'de';

    this._submitBtn = document.getElementById("submit-btn");
    this.companyField = document.getElementById("companyName")
    this.loginField = document.getElementById("login")
    this.passwordField = document.getElementById("password")
    this.invalidCredentialsMessage = document.querySelector('.invalid-field-credentials');
    const forms = document.querySelector('#select-login');
    this._passwordBtn = document.getElementById('password-show__button');
    this._isRememberInput = document.getElementById('remember');
    this._forgotPasswordButtonEl = document.querySelector('.forgot__button');
    this._loginViewEl = document.querySelector('.login-view');
    this._resetPasswordViewEl = document.querySelector('.restart-password-view');
    this._resetPasswordStepFirstWrapperEl = document.getElementById('restart-password__step_1');
    this._resetPasswordStepSecondWrapperEl = document.getElementById('restart-password__step_2');
    this._backToLoginButtonEl = document.querySelector('.restart-password__header');
    this._resetPasswordEmailInput = document.getElementById('resetEmail');
    this._resetPasswordButtonEl = document.getElementById('reset-password__button');
    this._resetPasswordAgainButtonEl = document.getElementById('reset_again-password__button');
    this._notificationElem = document.getElementById('notification');
    this._notificationCloseButtonEl = document.getElementById('notification__content__button__close');

    this._forgotPasswordButtonEl.addEventListener('click', this.goToResetPasswordView.bind(this));
    this._backToLoginButtonEl.addEventListener('click', this.goToLoginView.bind(this));
    this._resetPasswordButtonEl.addEventListener('click', this.resetPasswordSubmit.bind(this));
    this._resetPasswordAgainButtonEl.addEventListener('click', this.reSendResetPasswordEmail.bind(this));
    this._submitBtn.addEventListener('click', this.submit.bind(this));
    this._passwordBtn.addEventListener('click', this.showPassword.bind(this));
    this._notificationCloseButtonEl.addEventListener('click', this.closeNotification.bind(this));
    forms.addEventListener('submit', this.submit.bind(this));
    this.checkIsLogout();
    this.checkCredentials();
    this.toggleInvalidCredentials();

  }

  submit(event) {
    event.preventDefault()
    const selectedDataBaseField = Array.from(document.getElementsByName("database")).find(r => r.checked)

    this.loginToWebApps(this.companyField.value, this.loginField.value,
      selectedDataBaseField.value, this._isRememberInput.checked, this.passwordField.value)
  }

  loginToWebApps(companyName, username, database, isRemember, password) {
    this.loginService.login(companyName, username, database)
      .then(value => {
        const { url, userName } = value.data;
        const data = {
          Username: userName,
          Password: password,
          Date: new Date(),
          Language: this.language,
          IsRemember: isRemember
        };
        let token = this.cryptoJsService.encrypt(JSON.stringify(data))
          .replace(/\+/g, 'p1L2u3S')
          .replace(/\//g, 's1L2a3S4h')
          .replace(/=/g, 'e1Q2u3A4l');

        window.localStorage.setItem('credentials',
          JSON.stringify({
            Username: username,
            CompanyName: companyName,
            Database: database,
            IsRemember: isRemember,
            Password: this.cryptoJsService.encrypt(password)
          }));

        window.open(`${ url }${ token }`, '_self');
      }).catch(error => {
      this.toggleInvalidCredentials(false)
    })

  }


  toggleInvalidCredentials(error = true) {
    if (document.location.href.includes('error') || !error) {
      this.invalidCredentialsMessage.classList.remove('hidden');
    } else {
      this.invalidCredentialsMessage.classList.add('hidden');
    }
  }

  checkIsLogout() {
    if (document.location.href.includes('logout')) {
      const loginCredentials = JSON.parse(window.localStorage.getItem('credentials'));
      loginCredentials.password = ''
      loginCredentials.IsRemember = false;

      window.localStorage.setItem('credentials', JSON.stringify(loginCredentials))
    }
  }

  goToResetPasswordView() {
    this._loginViewEl.classList.add('d-none');
    this._resetPasswordViewEl.classList.remove('d-none');
    this._resetPasswordStepFirstWrapperEl.classList.remove('d-none');
  }

  resetPasswordSubmit() {
    this.loginService.resetPassword(this._resetPasswordEmailInput.value, this.language).then(() => {

    });
    this._resetPasswordStepFirstWrapperEl.classList.add('d-none');
    this._resetPasswordStepFirstWrapperEl.classList.remove('d-flex');
    this._resetPasswordStepSecondWrapperEl.classList.remove('d-none');
    this._resetPasswordStepSecondWrapperEl.classList.add('d-flex');
  }

  reSendResetPasswordEmail() {
    this.loginService.resetPassword(this._resetPasswordEmailInput.value, this.language).then(() => {})
    this._notificationElem.classList.remove('notification--hidden');
    this._notificationElem.classList.add('notification--show');

    setTimeout(() => {
      this._notificationElem.classList.remove('notification--show');
      this._notificationElem.classList.add('notification--hidden');
    }, 8000)

  }

  goToLoginView() {
    this._resetPasswordViewEl.classList.add('d-none');
    this._loginViewEl.classList.remove('d-none')
    this._resetPasswordStepFirstWrapperEl.classList.remove('d-none');
    this._resetPasswordStepFirstWrapperEl.classList.add('d-flex');
    this._resetPasswordStepSecondWrapperEl.classList.remove('d-flex');
    this._resetPasswordStepSecondWrapperEl.classList.add('d-none');

  }

  checkLanguage() {

    if (document.location.href.includes('/en') || document.location.href.includes('/de')) {
      return;
    }

    const usrlang = navigator.language
      || navigator.userLanguage;
    if (usrlang === 'de') {
      window.location = `${ document.location.origin }/de`;
    } else {
      window.location = `${ document.location.origin }/en`;
    }
  }

  closeNotification() {
    this._notificationElem.classList.remove('notification--show');
    this._notificationElem.classList.add('notification--hidden');
  }

  showPassword() {

    if (this._passwordBtn.classList.contains('cc-eye-blind')) {
      this._passwordBtn.classList.remove('cc-eye-blind')
      this._passwordBtn.classList.add('cc-eye')
      this.passwordField.type = 'text'
    } else {
      this._passwordBtn.classList.remove('cc-eye')
      this._passwordBtn.classList.add('cc-eye-blind')
      this.passwordField.type = 'password'
    }
  }

  checkCredentials() {
    if (window.localStorage.getItem('credentials')) {

      const {
        CompanyName,
        Database,
        Username,
        IsRemember,
        Password
      } = JSON.parse(window.localStorage.getItem('credentials'))

      if (IsRemember) {
        this.loginToWebApps(CompanyName, Username, Database, IsRemember, this.cryptoJsService.decrypt(Password));
        return
      }
      let databaseList = document.getElementsByName("database")

      databaseList.forEach(element => {

        if (element.value === Database) {
          element.checked = true;
        }

      })
      if (document.location.href.includes('error')) {
        this.loginField.value = Username
      }
      this.companyField.value = CompanyName;
    }

  }

}
