import axios from "axios";
import {Environment} from "../environments/Environment";

export class LoginService {
  constructor() {
     this.apiInstance = axios.create({
      baseURL: `${Environment.apiAdminUrl}/websuit/`,
      timeout: 5000,
    });
  }

  getInstances(email) {
    return this.apiInstance.get('get-instances-by-user',{
      params: {
        clientUserEmail: email
      }

    });
  }

  login(CompanyId, Login, InstanceId) {
    return this.apiInstance.post('login-to-web',{
      ClientName: CompanyId,
      Login,
      InstanceId,
    });
  }

  resetPassword(email, language) {
    return this.apiInstance.post('restart-password', {
      email,
      language
    })
  }
}
