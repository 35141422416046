export class FooterComponent {

  year = null
  footerYearText = null

  constructor() {
  }

  init() {
    this.footerYearText = document.getElementById('footerYear')
    this.year = new Date().getFullYear()

    this.footerYearText.innerHTML = `2016-${this.year}`

  }
}
